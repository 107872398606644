<template>
    <span v-if="!vlm">
        <button
          class="btn btn-sm btn-info"
          :class="{ 'hide': hideButton }"
          ref="mediaUrlButton"
          @click="showMediaUrlModal"
        >
            <i :class="fontAwesomeIcon"></i>
            {{ btnText }}
        </button>
        <app-media-url-modal
          v-if="mediaUrlModal"
          @close="closeMediaUrlModal"
          @show-media-upload-modal="showMediaUploadModal"
          @get-raw-url="getRawUrl"
          :type-of-modal="typeOfBtn"
          :media-url-modal-label-desc="mediaUrlModalLabelDesc"
        >
        </app-media-url-modal>
        <app-media-upload-modal
          v-if="mediaUploadModal"
          :files="filesFromUrl"
          :count=0
          @close="closeMediaUploadModal"
          @upload="addMedia"
          :default-input-value-of-author="rawUrl"
        >
        </app-media-upload-modal>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import MediaUrlModal from './MediaUrlModal'
import MediaUploadModal from './MediaUploadModal'

export default {
  name: 'MediaUrlButton',
  props: {
    btnText: {
      type: String,
      required: false,
      default: function () {
        return this.$t('buttons.upload_from_url')
      }
    },
    files: {
      type: String
    },
    fontAwesomeIcon: {
      type: String,
      required: false,
      default: 'fas fa-link'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    mediaUrlModalLabelDesc: {
      type: String,
      required: false
    },
    typeOfBtn: {
      type: String, // url or embed
      required: false,
      default: 'url'
    }
  },
  data () {
    return {
      mediaUrlModal: false,
      mediaUploadModal: false,
      filesFromUrl: '',
      rawUrl: ''
    }
  },
  components: {
    appMediaUrlModal: MediaUrlModal,
    appMediaUploadModal: MediaUploadModal
  },
  computed: {
    ...mapGetters(['vlm'])
  },
  methods: {
    showMediaUrlModal () {
      this.mediaUrlModal = true
      this.mediaUploadModal = false
    },
    closeMediaUrlModal () {
      this.mediaUrlModal = false
    },
    getRawUrl (data) {
      this.rawUrl = data.toString()
    },
    showMediaUploadModal (files) {
      this.filesFromUrl = files
      this.mediaUploadModal = true
      this.mediaUrlModal = false
    },
    closeMediaUploadModal () {
      this.mediaUploadModal = false
    },
    addMedia (media) {
      this.$emit('set-media', media)
    }
  }
}
</script>
