<template>
  <Modal
    :title="$t('dam.crop_modal_header')"
    :show-close-button="false"
  >
    <!-- Header -->
    <template slot="header">
      <AssetCropForm
        :asset="asset"
        @rotate-left="() => $refs.assetCropForm.rotate(270)"
        @rotate-right="() => $refs.assetCropForm.rotate(90)"
        @face-detect="() => $refs.assetCropForm.faceDetect()"
        @save-crop="() => $refs.assetCropForm.saveCrop()"
        @close="$emit('close')"
        show-close-button
        header
      />
    </template>

    <!-- Body -->
    <template slot="body">
      <AssetCropForm
        :asset="asset"
        ref="assetCropForm"
        class="asset-crop"
        @close="$emit('close')"
        @post-update="$emit('post-update', $event)"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import AssetCropForm from '@/components/dam/assetDetail/AssetCropForm'

export default {
  name: 'AssetCropModal',
  props: {
    asset: {
      type: Object
    }
  },
  components: {
    AssetCropForm,
    Modal
  }

}
</script>

<style lang="scss" scoped>
.asset-crop {
  display: flex;
  justify-content: center;
}
</style>
