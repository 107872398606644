<template>
  <app-modal
    :title="$t('modal.media_upload_header_2')"
    :show-close-button="!this.reading && !this.uploading"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        @click="onConfirmButton"
        type="button"
        :class="`btn btn-green ${confirmButtonDisabled ? 'btn-green--disabled' : ''}`"
        data-dismiss="modal"
        data-test="media_upload_done"
        :disabled="confirmButtonDisabled"
      >
        <i class="fa fa-check"></i> {{ confirmButtonLabel }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <div v-if="!failedReadFilesIgnored && failedReadFiles.length > 0" class="failed-files">
        <app-checkbox
          v-model="tryReadingAgain"
          :label="$t('media.try_reading_again')"
          id="tryReadingAgain"
        />
        <div>
          <span class="failed-files__label">
            {{ $t('media.failed_reading_files') }}
          </span>
          {{ failedReadFiles.map(f => f.name).join(', ') }}
        </div>
      </div>
      <div v-if="failedUploadedFiles.length > 0" class="failed-files">
        <app-checkbox
          v-model="tryUploadAgain"
          :label="$t('media.try_upload_again')"
          id="tryUploadAgain"
        />
        <div>
          <span class="failed-files__label">
            {{ $t('media.failed_uploaded_files') }}
          </span>
          {{ failedUploadedFiles.map(f => f.fileName).join(', ') }}
        </div>
      </div>
      <div v-if="uploading" class="m-t-50 m-b-50">
        <h5 class="text-center">{{ `${$t('modal.uploading')} ${uploadingImageCount} / ${count}` }}</h5>
        <app-progressbar></app-progressbar>
      </div>
      <div class="m-b-40" v-if="reading">
        <h5 class="text-center">{{ `${$t('modal.reading')} ${readingImageCount} / ${count}` }}</h5>
        <div class="progress">
          <div
            class="progress-bar bg-success wow animated progress-animated"
            style="width: 65%; height:6px;"
            role="progressbar"
          >
            <span class="sr-only">60% Complete</span>
          </div>
        </div>
      </div>
      <div class="row" v-if="!reading && !tryReadingAgain && !uploading">
        <div class="col-lg-9">
          <div v-for="(uploadedFile, index) in readFiles" :key="`item-${index}`">
            <div class="row">
              <div class="col-lg-4">
                <img
                  :src="uploadedFile.fileBase64"
                  width="100%"
                  class="m-b-10"
                /><br>
                <app-dam-image-check-dimensions
                  :file="uploadedFile.fileBase64"
                >
                </app-dam-image-check-dimensions>
              </div>
              <div class="col-lg-8">
                <small
                  v-if="uploadedFile.metadata.caption.length > 500"
                  class="form-control-feedback text-warning float-right"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  {{ $t('media.title_is_too_long') }}
                </small>
                <app-textarea
                  v-model.trim="uploadedFile.metadata.caption"
                  :label="$t('modal.title')"
                  :id="'media-caption-' + index"
                  :error="uploadedFile.metadata.caption.length === 0"
                >
                  <app-tooltip
                    :title="$t('dam.headline_seo_info')"
                    icon="fab fa-google"
                    customInfoClass="seo"
                  ></app-tooltip>
                </app-textarea>
                <app-input
                  v-model.trim="uploadedFile.metadata.author"
                  :label="$t('modal.author')"
                  :id="'media-author-' + index"
                  :error="errorAuthor"
                  :default-input-value="defaultInputValueOfAuthor"
                  @input="inputHandlerAuthor"
                  :readonly="authorReadOnly"
                >
                </app-input>
                <app-input
                  v-model.trim="uploadedFile.metadata.keywords"
                  :label="$t('modal.keywords')"
                  :id="'media-keywords-' + index"
                  :error="uploadedFile.metadata.keywords.length === 0"
                >
                </app-input>
                <app-checkbox
                  v-model="uploadedFile.metadata.hideCaption"
                  :label="$t('modal.hidden_caption_on_web')"
                  :id="'media-hidden-caption-on-web-' + index"
                  disableFormGroup
                >
                </app-checkbox>
              </div>
            </div>
            <hr v-if="index+1 < readFiles.length">
          </div>
        </div>
        <div class="col-lg-3">
          <div v-if="readFiles.length > 1">
            <strong>{{ $t('media.predetermined_values') }}</strong>
            <small
              v-if="defaultImageCaption.length > 500"
              class="form-control-feedback text-warning float-right"
            >
              <i class="fa fa-exclamation-triangle"></i>
              {{ $t('media.title_is_too_long') }}
            </small>
            <app-textarea
              v-model.trim="defaultImageCaption"
              :label="$t('modal.title')"
              id="media-caption-default"
            >
              <app-tooltip
                :title="$t('dam.headline_seo_info')"
                icon="fab fa-google"
                customInfoClass="seo"
              ></app-tooltip>
            </app-textarea>
            <app-input
              v-model.trim="defaultImageAttribution"
              :label="$t('modal.author')"
              id="media-author-default"
            >
            </app-input>
            <app-textarea
              v-model.trim="defaultImageKeywords"
              :label="$t('modal.keywords')"
              id="media-keywords-default"
            >
            </app-textarea>
            <button
              class="btn btn-danger"
              @click="removeTitles"
            >
              {{ $t('media.remove_all_titles') }}
            </button>
            <br>
            <button
              class="btn btn-info m-t-10"
              @click="setPredeterminedTitles"
            >
              {{ $t('media.set_predetermined_titles') }}
            </button>
          </div>
          <div class="checkbox checkbox-success m-t-20 m-b-20"
               v-if="showPageBreaksCheckbox && readFiles.length > 1"
          >
            <input
              type="checkbox"
              id="addPageBreaks"
              class="filled-in"
              v-model="pageBreaks"
            >
            <label class="custom-control custom-checkbox" for="addPageBreaks">
              <small>{{ $t('media.add_pagebreak_text') }}</small>
            </label>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Input from '../form/inputs/Input'
import Textarea from '../form/Textarea'
import Checkbox from '../form/Checkbox'
import Progressbar from '../Progressbar'
import CoreApi from '../../api/core'
import MediaService from '../../services/media/MediaService'
import DamImageCheckDimensions from '../dam/DamImageCheckDimensions'
import Modal from './Modal'
import NotifyService from '../../services/NotifyService'
import Config from '../../config/index'
import Tooltip from '../tooltip/Tooltip'
import ErrorHandlingService from '@/services/ErrorHandlingService'

export default {
  name: 'MediaUploadModal',
  props: {
    defaultInputValueOfAuthor: {
      type: String,
      required: false,
      default: ''
    },
    files: {
      type: Array
    },
    reading: {
      type: Boolean,
      default: false
    },
    failedReadFiles: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number
    },
    showPageBreaksCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uploading: false,
      timeoutError: false,
      readFiles: [],
      failedUploadedFiles: [],
      tryUploadAgain: false,
      tryReadingAgain: false,
      failedReadFilesIgnored: false,
      errorAuthor: true,
      authorReadOnly: false,
      uploadedFile: {
        fileBase64: '',
        metadata: {
          caption: '',
          author: '',
          showAuthor: true,
          keywords: '',
          hideCaption: false,
          transmissionReference: ''
        }
      },
      damMediaResponse: {},
      createdDamMedia: [],
      defaultImageCaption: '',
      defaultImageAttribution: '',
      defaultImageKeywords: '',
      pageBreaks: false
    }
  },
  computed: {
    confirmButtonDisabled () {
      return this.uploading || this.reading || (!this.isValid && !this.tryReadingAgain)
    },
    confirmButtonLabel () {
      return this.tryReadingAgain || this.tryUploadAgain ? this.$t('media.try_again') : this.$t('modal.done')
    },
    readingImageCount () {
      return this.readFiles.length
    },
    uploadingImageCount () {
      return this.createdDamMedia.length
    },
    isValid () {
      if (this.defaultImageCaption !== '' && this.defaultImageKeywords !== '') {
        return true
      }
      let isValid = true
      for (let i = 0; i < this.readFiles.length; i++) {
        if (this.readFiles[i].metadata.caption === '' && this.defaultImageCaption === '') {
          isValid = false
        }
        if (this.readFiles[i].metadata.keywords.length === 0 && this.defaultImageKeywords === '') {
          isValid = false
        }
      }
      return isValid
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    appCheckbox: Checkbox,
    appProgressbar: Progressbar,
    appDamImageCheckDimensions: DamImageCheckDimensions,
    appTooltip: Tooltip
  },
  methods: {
    inputHandlerAuthor (input) {
      input.length === 0 ? this.errorAuthor = true : this.errorAuthor = false
    },
    close () {
      this.$emit('close')
    },
    load () {
      this.readFiles = this.files
    },
    getFilesToBeUploadedToCore () {
      if (this.failedUploadedFiles.length === 0) {
        return this.readFiles
      } else {
        if (this.tryUploadAgain) {
          return this.failedUploadedFiles
        } else {
          return [] // user chose to ignore the failed files
        }
      }
    },
    async onConfirmButton () {
      if (this.tryReadingAgain) {
        this.$emit('read-files-again')
      } else {
        this.failedReadFilesIgnored = true
        await this.uploadFiles()
      }
    },
    async uploadFiles () {
      const uploadedFiles = this.getFilesToBeUploadedToCore()
      this.failedUploadedFiles = []
      this.uploading = true
      const chunkSize = MediaService.DAM_UPLOAD_CHUNK_SIZE
      for (let i = 0; i < uploadedFiles.length; i += chunkSize) {
        const chunkUploadedFiles = uploadedFiles.slice(i, i + chunkSize)
        const promises = chunkUploadedFiles.map(this.uploadFile)
        await Promise.all(promises)
      }
      this.uploading = false
      if (this.failedUploadedFiles.length === 0) {
        this.$emit('add-page-breaks', this.pageBreaks)
        this.$emit('upload', this.createdDamMedia)
        this.close()
      } else {
        this.tryUploadAgain = true
      }
    },
    async uploadFile (file) {
      const bodyFormData = new FormData()
      const caption = file.metadata.caption === '' ? this.defaultImageCaption : file.metadata.caption
      const author = file.metadata.author === '' ? this.defaultImageAttribution : file.metadata.author
      const keywords = file.metadata.keywords.length === 0 ? this.defaultImageKeywords : file.metadata.keywords
      bodyFormData.set('image', MediaService.dataUriToBlob(file.fileBase64))
      bodyFormData.set('caption', caption)
      bodyFormData.set('byLine', this.defaultInputValueOfAuthor.length > 0 ? this.defaultInputValueOfAuthor : author)
      bodyFormData.set('keywords', keywords)
      bodyFormData.set('hideCaption', file.metadata.hideCaption)
      return CoreApi(Config.dam.apiUploadTimeout).post('/dam/upload', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(async response => {
          this.createdDamMedia.push(response.data)
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && this.timeoutError === false) {
            this.timeoutError = true
            NotifyService.setErrorMessage(this.$t('notify.upload_timeout_error'), '', -1)
          }
          this.failedUploadedFiles.push(file)
          console.error(`Uploading of '${file.fileName}' to core-cms failed.`)
          console.error(error)
          const { message, stack } = error
          ErrorHandlingService.vueErrorHandler({ message, stack }, this, { url: 'POST /dam/upload', payload: bodyFormData, filename: file.fileName })
        })
    },
    removeTitles () {
      this.readFiles.forEach(uploadedFile => {
        uploadedFile.metadata.caption = ''
      })
    },
    setPredeterminedTitles () {
      this.readFiles.forEach(uploadedFile => {
        uploadedFile.metadata.caption = this.defaultImageCaption
        uploadedFile.metadata.keywords = this.defaultImageKeywords
        uploadedFile.metadata.author = this.defaultImageAttribution
      })
    }
  },
  watch: {
    failedReadFiles (value) {
      this.tryReadingAgain = value?.length > 0
    }
  },
  created () {
    if (this.defaultInputValueOfAuthor.length > 0) {
      this.errorAuthor = false
      this.authorReadOnly = true
    }
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.btn-green--disabled, .btn-green--disabled:hover {
  color: lighten(#465674, 20%);
  background-color: lighten(#D1DBE4, 5%);
  cursor: default !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
.failed-files {
  margin-bottom: 1rem;
  &__label {
    color: red;
  }
}
</style>
