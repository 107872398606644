<template>
    <span>
        <button
          class="btn-media-upload"
          :class="{ 'hide': hideButton }"
          id="mediaUploadButton"
          ref="mediaUploadButton"
          @click="openFileDialog()"
        >
            <slot>
              <i class="fas fa-upload"></i>
              {{ buttonText }}
            </slot>
        </button>
        <input
          type="file"
          ref="galleryFile"
          style="display: none"
          @change="readFiles"
          :multiple="multiple"
          accept="image/png, image/jpeg"
        >
        <app-media-upload-modal
          v-if="mediaUploadModal"
          :files="uploadedFiles"
          :reading="reading"
          :failed-read-files="failedFiles"
          :count="count"
          :showPageBreaksCheckbox="showPageBreaksCheckbox"
          @read-files-again="readFilesAgain"
          @close="closeMediaUploadModal"
          @upload="addMedia"
          @add-page-breaks="addPageBreaks"
        >
        </app-media-upload-modal>
    </span>
</template>

<script>
import MediaUploadModal from '../shared/MediaUploadModal'
import MediaService from '../../services/media/MediaService'
import DamApi from '../../api/dam'
import { SOURCE_EAGLE_IMAGE } from '../../model/ValueObject/DamUploadSources'
import NotifyService from '../../services/NotifyService'
import Config from '../../config/index'
import ErrorHandlingService from '@/services/ErrorHandlingService'

export default {
  name: 'MediaUploadButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    showPageBreaksCheckbox: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: function () {
        return this.$t('buttons.upload_from_computer')
      }
    }
  },
  data () {
    return {
      failedFiles: [],
      uploadedFiles: [],
      reading: false,
      count: 0,
      mediaUploadModal: false,
      createdDamMedia: [],
      timeoutError: false
    }
  },
  components: {
    appMediaUploadModal: MediaUploadModal
  },
  methods: {
    async readFiles (event) {
      this.uploadedFiles = []
      this.timeoutError = false
      const files = Object.values(event.target.files)
      this.count = event.target.files.length
      this.showMediaUploadModal()

      await this.readFilesInChunks(files)

      // Clear input file value, prevent to open same file
      this.$refs.galleryFile.value = ''
    },
    async readFilesAgain () {
      await this.readFilesInChunks(this.failedFiles)
    },
    async readFilesInChunks (files) {
      this.reading = true
      this.failedFiles = []
      const chunkSize = MediaService.DAM_UPLOAD_CHUNK_SIZE
      for (let i = 0; i < files.length; i += chunkSize) {
        const chunkFiles = files.slice(i, i + chunkSize)
        const promises = chunkFiles.map(this.readFile)
        await Promise.all(promises)
      }
      this.reading = false
    },
    readFile (file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = (event) => {
          resolve(this.extractIptc(event.target.result, file))
        }
        reader.readAsDataURL(file)
      })
    },
    extractIptc (image, file) {
      const bodyFormData = new FormData()
      bodyFormData.set('image', MediaService.dataUriToBlob(image))
      bodyFormData.set('uploadSource', SOURCE_EAGLE_IMAGE)
      return DamApi(Config.dam.apiExtractTimeout).post('/metadata/image', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          const uploadedFile = {
            fileBase64: image,
            fileName: file.name,
            metadata: {
              caption: response.data.headline,
              author: response.data.byLine,
              keywords: response.data.keywords,
              showAttribution: true,
              hideCaption: false
            }
          }
          this.uploadedFiles.push(uploadedFile)
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && this.timeoutError === false) {
            this.timeoutError = true
            NotifyService.setErrorMessage(this.$t('notify.extract_timeout_error'), '', -1)
          }
          this.failedFiles.push(file)
          console.error(`Uploading of '${file.name}' to DAM failed.`)
          console.error(error)
          const { message, stack } = error
          ErrorHandlingService.vueErrorHandler({ message, stack }, this, { url: 'POST /metadata/image', payload: bodyFormData, filename: file.name })
        })
    },
    showMediaUploadModal () {
      this.mediaUploadModal = true
    },
    closeMediaUploadModal () {
      this.mediaUploadModal = false
    },
    addMedia (media) {
      this.$emit('set-media', media)
    },
    addPageBreaks (pageBreaks) {
      this.$emit('add-page-breaks', pageBreaks)
    },
    openFileDialog () {
      this.$refs.galleryFile.click()
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-media-upload {
    @include font(500 13px "Roboto");
    @include padding(7px 14px);
    @include radius(4px);
    cursor: pointer;
    // display: inline-block;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    @include bp(10) {
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
  }
</style>
