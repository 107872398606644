<template>
  <button
    :class="{
      'module-form-button': true,
      [`module-form-button_${icon}`]: icon,
      'module-form-button--medium': medium,
      'module-form-button--disabled': disabled
    }"
    type="button"
    :data-test="`module-form-button-${icon}`"
    :title="tooltip ? tooltip : title"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <slot>
      <component
        v-if="icon"
        :is="iconComponentName"
        :class="`module-form-button__icon module-form-button_${icon}__icon`"
      />
      <div v-if="title" class="module-form-button__title">
        {{ title }}
      </div>
    </slot>
  </button>
</template>

<script>
import IconEdit from '@/assets/img/svg/edit.svg?inline'
import IconDelete from '@/assets/img/svg/delete.svg?inline'
import IconClose from '@/assets/img/svg/cross.svg?inline'

export default {
  name: 'ModuleFormButton',
  props: {
    icon: { // '', 'edit', 'delete', 'close'
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconEdit,
    IconDelete,
    IconClose
  },
  computed: {
    iconComponentName () {
      const iconName = this.icon.charAt(0).toUpperCase() + this.icon.slice(1)
      return `Icon${iconName}`
    }
  }
}
</script>

<style scoped lang="scss">
.module-form-button {
  border-radius: rem(4px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #6599FE;
  color: #FFFFFF;
  &--disabled {
    background-color: #D1DBE4;
    cursor: default;
    &:hover {
      background-color: #D1DBE4;
    }
  }
  &--medium {
    height: rem(40px);
    width: rem(40px);
  }
  &__icon {
    width: rem(12px);
    height: auto;
    fill: #FFFFFF;
  }
  &_delete {
    background-color: #E9596F;
  }
  &_close {
    background-color: #FFFFFF;
    color: #9DAAC4;
    &__icon {
      fill: #9DAAC4;
    }
  }
  &__title {
    padding-left: rem(5px);
    padding-right: rem(5px);
    font-size: rem(13px);
    font-weight: 700;
  }
}
</style>
