<template>
  <app-modal
    :title="$t('modal.media_search_header')"
    modal-size="media-modal modal-lg"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <div class="col-lg-8">
          <app-dam-filter :pagination="false" :default-filter="defaultFilter"></app-dam-filter>
        </div>
        <div class="col-lg-2">
          <button
            v-if="assets.length"
            @click="addMedia"
            type="button"
            class="btn btn-block btn-green"
            data-test="media_btn_addMedia"
            :disabled="this.selectedMedias.length === 0"
          >
            <i class="fa fa-check"></i> {{ $t('modal.select') }} ({{ this.selectedMedias.length }})
          </button>
        </div>
      </div>
      <div class="row m-t-20">
        <div class="col-8">
          <div class="media-list" id="mediaList">
            <ul class="list-inline">
              <li
                v-for="(asset, index) in assets"
                :key="`item-${index}`"
                @mouseover="showMediaMeta(asset)"
                @mouseleave="hideMediaMeta"
                @click="selectMedia(asset, $event, index)"
              >
                <div
                  :style="'background-image: url(' + getDamImagePath(asset.basename, 288) + ')'"
                  class="media-list-item"
                  :data-id="asset.id"
                >
                  <template v-if="asset.restrictedSites[0] !== 'none'">
                    <span
                      class="label label-danger m-r-5 float-right"
                      v-for="(restrictedSite, index) in asset.restrictedSites"
                      :key="`item-${index}`"
                    >
                      {{ getFullSiteShortTitle(restrictedSite) }}
                    </span>
                  </template>
                  <span
                    class="label label-info m-r-5 float-right"
                    v-if="asset.createdBy === getTasrUser"
                  >
                                    TASR
                                </span>
                  <div class="media-action-buttons">
                    <a
                      class="original-download-url"
                      @click="downloadAsset(asset)"
                      title="Download origin"
                    >
                      <i class="fa fa-download"></i>
                    </a>
                    <a
                      class="copy-embed-id"
                      title="Copy Embed Id"
                      @click="copyEmbedId(asset.id, $event)"
                    >
                      <i class="fas fa-copy"></i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <app-preloader v-if="callingAPI" style="z-index:9999"></app-preloader>
          <div class="row">
            <div class="col-lg-4">
              <p class="m-t-20">{{ $t('found') }}: {{ totalCount }}</p>
            </div>
            <div class="col-4 text-center">
              <button
                class="btn btn-info m-t-10"
                v-if="assets.length < totalCount"
                @click="loadMore"
              >
                {{ $t('modal.load_more') }}
              </button>
            </div>
            <div class="col-lg-4">
            </div>
          </div>
        </div>
        <div class="col-4">
          <div v-if="asset" class="media-metadata-info">
            <h4>{{ $t('media.metadata_info_header') }}</h4>
            <div class="text-left">
              <img
                :src="getDamImagePath(asset.basename, 608)"
                class="m-b-10"
                height="270"
              />
            </div>
            <p>
              <strong>{{ $t('media.headline') }}:</strong>
              {{ asset.metadata.headline }}
            </p>
            <p>
              <strong>{{ $t('media.caption') }}:</strong>
              {{ asset.metadata.caption }}
            </p>
            <p>
              <strong>{{ $t('media.description') }}:</strong>
              {{ asset.metadata.description }}
            </p>
            <p v-if="asset.restrictedSites[0] !== 'none'">
              <strong>{{ $t('dam.restricted_sites') }}</strong>
              <span
                class="label label-danger m-r-5"
                v-for="(restrictedSite, index) in asset.restrictedSites"
                :key="`item-${index}`"
              >
                {{ getFullSiteShortTitle(restrictedSite) }}
              </span>
            </p>
            <p>
              <strong>{{ $t('media.author') }}:</strong>
              {{ asset.metadata.byLine }}
            </p>
            <p>
              <strong>{{ $t('media.keywords') }}:</strong>
              <span
                class="label label-info m-r-5"
                v-for="(keyword, index) in asset.metadata.keywords"
                :key="`item-${index}`"
              >
                {{ keyword }}
              </span>
            </p>
            <p>
              <strong>{{ $t('media.created') }}:</strong>
              {{ asset.createdAt | prettyDateTime }}
            </p>
          </div>
        </div>
      </div>
      <div class="media-preview-list-box border-top" id="mediaPreviewListBox">
        <div class="media-preview-list m-t-10">
          <ul class="list-inline">
            <li v-for="(asset, index) in selectedMedias" :key="`item-${index}`">
              <img :src="getDamImagePath(asset.basename)" height="80">
              <i
                class="fa fa-trash del media-delete"
                title="Remove"
                data-test="media_removeMedia"
                @click="removeSelectedMedia(asset, index)"
              ></i>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import DamFilter from '../dam/DamFilter'
import Preloader from '../preloader/Preloader'
import MediaMixin from '../mixins/Media'
import Modal from './Modal'
import ClipboardService from '../../services/ClipboardService'
import NotifyService from '../../services/NotifyService'

const LIMIT = 20
const USER_TASR = 'nmh.tasr'

export default {
  name: 'DamSearchModal',
  mixins: [MediaMixin],
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    defaultFilter: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      loading: false,
      showLoadMore: false,
      asset: null,
      selectedMedias: []
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    assets () {
      return this.$store.getters['dam/list']
    },
    limit () {
      return this.$store.getters['dam/limit']
    },
    maxCreatedAt () {
      return this.$store.getters['dam/maxCreatedAt']
    },
    totalCount () {
      return this.$store.getters['dam/totalCount']
    },
    getAllSites () {
      return this.$store.getters['site/all']
    },
    getTasrUser () {
      return USER_TASR
    }
  },
  components: {
    appModal: Modal,
    appDamFilter: DamFilter,
    appPreloader: Preloader
  },
  methods: {
    searchAssets () {
      this.$store.commit('dam/setMaxCreatedAt', '')
      this.$store.commit('dam/setPage', 1)
      this.$store.commit('dam/setLimit', LIMIT)
      this.$store.dispatch('dam/fetch')
        .then(() => {
          this.showLoadMore = true
        })
    },
    setMaxCreatedAt () {
      const maxCreatedAt = this.assets[this.assets.length - 1].createdAt
      this.$store.commit('dam/setMaxCreatedAt', maxCreatedAt)
    },
    loadMore () {
      this.setMaxCreatedAt()
      this.$store.commit('dam/incrementPage')
      this.$store.dispatch('dam/fetch')
        .then(() => {
          this.scrollListDown()
        })
    },
    downloadAsset (asset) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$store.dispatch('dam/download', asset)
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    scrollListDown () {
      setTimeout(() => {
        const div = document.getElementById('mediaList')
        div.scrollTop = div.scrollHeight - div.clientHeight
      }, 200)
    },
    scrollPreviewListRight () {
      setTimeout(() => {
        const div = document.getElementById('mediaPreviewListBox')
        div.scrollLeft = div.scrollWidth - div.clientWidth
      }, 200)
    },
    copyEmbedId (embedId, event) {
      ClipboardService.copy(embedId, event)
      NotifyService.setSuccessMessage(this.$t('notify.id_was_copied'))
    },
    showMediaMeta (asset) {
      this.asset = asset
    },
    hideMediaMeta () {
      this.asset = null
    },
    selectMedia (item, event, index) {
      if (event.target.classList.contains('media-list-item')) {
        if (event.target.classList.contains('media-list-item--selected')) {
          event.target.classList.remove('media-list-item--selected')
          const filtered = this.selectedMedias.filter(media => media.id !== item.id)
          this.selectedMedias = filtered
          return
        } else {
          event.target.classList.add('media-list-item--selected')
        }
      }
      if (this.multiple === false) {
        this.selectedMedias = []
      }
      if (this.checkIfSelected(item) === false) {
        this.selectedMedias.push(item)
      }
      this.scrollPreviewListRight()
    },
    checkIfSelected (asset) {
      let selected = false
      this.selectedMedias.forEach(media => {
        if (media.id === asset.id) {
          selected = true
        }
      })
      return selected
    },
    removeSelectedMedia (asset, index) {
      const assetId = this.$el.querySelector(`.media-list-item[data-id="${asset.id}"]`)
      assetId.classList.remove('media-list-item--selected')
      this.selectedMedias.splice(index, 1)
    },
    close () {
      this.$emit('close')
    },
    addMedia () {
      this.$emit('show-media-edit-modal', this.selectedMedias)
    },
    getFullSiteShortTitle (siteName) {
      return this.getAllSites.find(site => site.name === siteName).shortTitle
    }
  },
  mounted () {
    this.searchAssets()
  }
}
</script>

<style lang="scss" scoped>
.media-list {
  max-height: 515px;
  overflow: hidden;
  overflow-y: auto;
}

.media-list-item {
  width: 176px;
  height: 110px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #cccccc;
  cursor: pointer;
  margin-bottom: 7px;
  position: relative;
  &--selected {
    border: 3px solid #6599fe;
  }
}

.media-list-item:hover:before,
.media-list-item.active:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f058";
  position: absolute;
  color: #fff;
  right: 7px;
  bottom: 5px;
  z-index: 1;
  font-size: 20px;
}

.media-list-item:hover:after,
.media-list-item.active.active:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 60px 60px;
  border-color: transparent transparent #f85d6b transparent;
  right: 0;
  bottom: 0;
}

.media-list-item:hover,
.media-list-item.active {
  border: 3px solid #f85d6b;
}

.media-action-buttons {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 999;

  .original-download-url,
  .copy-embed-id {
    background: #2f3d4a;
    width: 26px;
    height: 26px;
    padding: 5px;
    display: none;
  }

  a {
    float: left;
    margin-right: 2px;
  }

  .fa,
  .fas {
    color: #ffffff;
    font-size: 18px;
  }
}

.media-list-item:hover .original-download-url,
.media-list-item:hover .copy-embed-id {
  display: block;
}

.media-metadata-info {
  font-size: 14px;
  height: 560px;
  overflow: hidden;
  overflow-y: auto;

  p {
    margin-bottom: 10px;
  }
}

.media-preview-list-box {
  height: 100px;
  overflow: hidden;
  overflow-x: auto;
}

.media-preview-list {
  width: auto;
  white-space: nowrap;

  li {
    position: relative;
    text-align: center;
  }

  li:hover {
    .media-delete {
      display: block;
    }
  }
}

.media-delete {
  position: absolute;
  top: 0;
  right: 8px;
  background: #dc3545;
  z-index: 1;
  padding: 5px;
  display: none;
  color: #fff;
  cursor: pointer;
}

</style>
