<template>
  <section>
    <MediaEditable
      v-if="hasImage"
      :disabled="disabled"
      :media="image"
      :width="360"
      :height="202"
      :show-add-media-to-gallery="showAddMediaToGallery"
      @add-media-to-gallery="$emit('@add-media-to-gallery', $event)"
      @remove-media="$emit('remove-media', $event)"
    />
    <div
      v-else-if="!hasImage && !disabled && !buttonsOnly"
      :class="{ 'upload-photo-box': true, 'upload-photo-box--error': error }"
      @click="() => selectClick ? selectClick() : $refs.uploadButton.openFileDialog()"
    >
      <table>
        <tr><td class="upload-photo-box__icon-box">
          <PlusIcon class="upload-photo-box__icon" />
        </td></tr>
        <tr><td>
          {{ selectText }}
        </td></tr>
      </table>
    </div>
    <div
      v-if="!disabled"
      :class="{ 'buttons-group': true, 'buttons-group--buttons-center': !buttonsOnly }"
    >
      <slot name="buttons">
        <slot name="firstButton" />
        <MediaUploadButton
          ref="uploadButton"
          class="buttons-group__button"
          :button-text="$t('buttons.upload_photo_from_computer')"
          @set-media="$emit('set-media', $event)"
        />
        <MediaSelectButton
          ref="mediaSelectButton"
          class="buttons-group__button"
          :button-text="$t('buttons.select_photo_from_bank')"
          @set-media="$emit('set-media', $event)"
        />
        <MediaUrlButton
          class="buttons-group__button"
          type-of-btn="url"
          :btn-text="$t('buttons.upload_photo_from_url')"
          @set-media="$emit('set-media', $event)"
        />
        <MediaUrlButton
          class="buttons-group__button"
          type-of-btn="embed"
          :btn-text="$t('buttons.upload_photo_from_url_social')"
          @set-media="$emit('set-media', $event)"
        />
      </slot>
    </div>
    <div v-if="!buttonsOnly" class="separator" />
  </section>
</template>

<script>
import MediaEditable from '@/components/shared/MediaEditable'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'

export default {
  name: 'ModuleFormPhotoBox',
  components: {
    PlusIcon,
    MediaUrlButton,
    MediaSelectButton,
    MediaUploadButton,
    MediaEditable
  },
  props: {
    image: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    buttonsOnly: {
      type: Boolean,
      default: false
    },
    showAddMediaToGallery: {
      type: Boolean,
      default: false
    },
    selectText: {
      type: String,
      default: function () { return this.$t('media.select_photo') }
    },
    selectClick: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    hasImage () {
      return this.image && Object.keys(this.image).length > 0
    }
  },
  methods: {
    showDamSearchModal (damSearchDefaultFilter) {
      this.$refs.mediaSelectButton.showDamSearchModal(damSearchDefaultFilter)
    }
  }
}
</script>
<style scoped lang="scss">
.upload-photo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #FFFFFF;
  border: solid #D1DBE4 rem(1px);
  border-radius: rem(5px);
  margin-bottom: 1rem;
  color: #465674;
  cursor: pointer;
  &--error {
    border-color: #FF3455;
  }
  &__icon-box {
    display: flex;
    justify-content: center;
    padding-bottom: rem(8px);
  }
  &__icon {
    @include size(rem(30px));
    fill: #D3DCEC;
    float: left;
  }
}
.buttons-group {
  display: flex;
  gap: 1%;
  &--buttons-center {
    justify-content: center;
  }
}
.separator {
  margin-top: rem(20px);
  margin-bottom: rem(20px);
  background: #D1DBE4;
  height: rem(1px);
}
</style>
