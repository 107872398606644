<template>
  <app-modal
    :title="$t('modal.edit_images')"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <app-preloader v-if="isLoading"></app-preloader>
      <button
        v-if="selectedMedia.length === 1"
        type="button"
        class="btn btn-inverse"
        @click="faceDetect"
      >
        <i class="fab fa-centercode"></i> {{ $t('dam.crop_modal_face_detect') }}
      </button>
      <template v-if="site !== null && selectedMedia.length > 1">
          <button
              v-if="showGallery"
              @click="toggleGallery"
              type="button"
              class="btn btn-danger"
          >
              {{ $t('modal.cancel_create_gallery') }}
          </button>
          <button
              v-else
              @click="toggleGallery"
              type="button"
              class="btn btn-info"
          >
              {{ $t('modal.create_gallery') }}
          </button>
      </template>
      <button
        @click="saveGalleryMedia"
        type="button"
        class="btn btn-green"
        data-dismiss="modal"
        data-test="media_btn_saveGalleryMedia"
        :disabled="!isValid || isLoading"
      >
        <i class="fa fa-check"></i> {{ insertLabel }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <div class="row gallery" v-if="showGallery">
        <div class="col-lg-9">
          <app-input
            v-model="gallery.title"
            :error="gallery.title.length === 0"
            id="gallery_title"
            :label="$t('modal.title')"
            required
            show-counter
          ></app-input>
          <app-textarea
            v-model="gallery.description"
            disable-form-group
            id="gallery_description"
            :label="$t('modal.description')"
            :rows="5"
            show-counter
            class="form-group"
          ></app-textarea>
          <app-select
            v-model="gallery.site"
            :options="sites"
            id="gallery_site"
            :label="$t('modal.site')"
            required
            :disabled="'disabled'"
          ></app-select>
          <div class="row">
            <div class="col-lg-5">
              <label>{{ $t('modal.tags') }}</label>
              <span class="required">*</span>
            </div>
            <div class="col-lg-7">
              <app-create-tag-button
                v-if="searchedTag"
                :searchedTag="searchedTag"
                @set-tag="addTag"
              ></app-create-tag-button>
            </div>
          </div>
          <app-multi-select
            v-model="gallery.expanded.tags"
            :options="tags"
            label="title"
            track-by="id"
            :preselect-first="true"
            :internal-search="false"
            :options-limit="300"
            :limit="10"
            :max-height="600"
            :show-no-results="false"
            open-direction="bottom"
            @search-change="findTag"
            id="gallery_tags"
            class="form-group"
          ></app-multi-select>
        </div>
        <div class="col-lg-3">
          <app-checkbox
            v-model="gallery.public"
            :label="$t('modal.public')"
            id="gallery_public"
            class="m-t-20"
            :tooltip="$t('dam.public_info')"
          ></app-checkbox>
        </div>
      </div>
      <div class="row">
        <div :class="{'col-lg-9': selectedMedia.length > 1, 'col-lg-12': selectedMedia.length === 1}">
          <div v-for="(media, index) in selectedMedia" :key="`item-${index}`" class="m-b-20">
            <div class="row">
              <div ref="cropperContainer" :class="{'col-lg-4': selectedMedia.length > 1, 'col-lg-8': selectedMedia.length === 1}">
                <i
                  class="fa fa-trash del media-delete media-edit-modal__remove-selected-media"
                  :title="$t('media.button_remove')"
                  data-test="media_removeMedia"
                  @click="removeSelectedMedia(index)"
                >
                </i>
                <app-preloader v-if="selectedMedia.length === 1 && !dataLoaded"></app-preloader>
                <app-vue-cropper
                  v-if="selectedMedia.length === 1"
                  :key="cropperKey"
                  v-show="dataLoaded"
                  id="croppedImage"
                  ref="cropper"
                  :guides="true"
                  :view-mode="1"
                  drag-mode="crop"
                  :auto-crop-area="1"
                  :min-container-width="croppedImageWidth"
                  :min-container-height="croppedImageHeight"
                  :background="true"
                  :rotatable="true"
                  :src="getDamImagePath(media.damMediaEmbed.filename)"
                  alt="Source Image"
                  :ready="getDamCrop"
                  center
                  :aspect-ratio=getAspectRatio()
                  :check-cross-origin="false"
                >
                </app-vue-cropper>
                <img
                  v-if="selectedMedia.length > 1 && media.damMediaEmbed && media.damMediaEmbed.filename !== ''"
                  :src="getDamImagePath(media.damMediaEmbed.filename, 320, 180)"
                  width="100%"
                  class="m-b-10"
                /><br>
                <h6 class="card-subtitle text-center">{{ media.imageAttribution }}</h6>
                <div v-if="media.dimensions" class="check-image-warning">
                  <p
                    v-if="media.size > maxFileSize"
                    class="alert alert-warning"
                  >
                    <i class="fa fa-exclamation-triangle"></i> {{ $t('dam.image_check_info_max_size') }}
                  </p>
                  <p
                    v-if="media.dimensions.width < minFileWidth"
                    class="alert alert-warning"
                  >
                    <i class="fa fa-exclamation-triangle"></i> {{ $t('dam.image_check_info_low_quality') }}
                    {{ media.dimensions.width }}x{{ media.dimensions.height }} px
                  </p>
                </div>
              </div>
              <div :class="{'col-lg-8': selectedMedia.length > 1, 'col-lg-4': selectedMedia.length === 1}">
                <small
                  v-if="media.imageCaption.length > 500"
                  class="form-control-feedback text-warning float-right"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  {{ $t('media.title_is_too_long') }}
                </small>
                <app-textarea
                  v-model.trim="media.imageCaption"
                  :label="$t('modal.description')"
                  :id="'media-caption-' + index"
                  :error="media.imageCaption.length === 0"
                >
                  <app-tooltip
                    :title="$t('dam.headline_seo_info')"
                    icon="fab fa-google"
                    customInfoClass="seo"
                  ></app-tooltip>
                </app-textarea>
                <app-input
                  v-model.trim="media.imageAttribution"
                  :label="$t('modal.author')"
                  :id="'media-author-' + index"
                  :error="media.imageAttribution.length === 0"
                >
                </app-input>
                <app-checkbox
                  v-model="media.hideCaption"
                  :label="$t('modal.hidden_caption_on_web')"
                  :id="'media-hidden-caption-on-web-' + index"
                  :error="media.hideCaption.length === 0"
                  disableFormGroup
                >
                </app-checkbox>
                <app-checkbox
                  v-show="isGallery === false"
                  v-model="media.exportWoodWing"
                  :label="$t('modal.export_wood_wing')"
                  :id="'media-export-wood-wing-on-web-' + index"
                  :error="media.exportWoodWing.length === 0"
                  disableFormGroup
                >
                </app-checkbox>
              </div>
            </div>
            <hr v-if="index+1 < selectedMedia.length">
          </div>
        </div>
        <div class="col-lg-3" v-if="selectedMedia.length > 1">
          <div v-if="selectedMedia.length > 1">
            <strong>{{ $t('media.predetermined_values') }}</strong>
            <small
              v-if="defaultImageCaption.length > 500"
              class="form-control-feedback text-warning float-right"
            >
              <i class="fa fa-exclamation-triangle"></i>
              {{ $t('media.title_is_too_long') }}
            </small>
            <app-textarea
              v-model.trim="defaultImageCaption"
              :label="$t('modal.title')"
              id="media-caption-default"
            >
              <app-tooltip
                :title="$t('dam.headline_seo_info')"
                icon="fab fa-google"
                customInfoClass="seo"
              ></app-tooltip>
            </app-textarea>
            <app-input
              v-model.trim="defaultImageAttribution"
              :label="$t('modal.author')"
              id="media-author-default"
            >
            </app-input>
            <button
              class="btn btn-danger"
              @click="removeTitles"
            >
              {{ $t('media.remove_all_titles') }}
            </button>
            <br>
            <button
              class="btn btn-info m-t-10"
              @click="setPredeterminedTitles"
            >
              {{ $t('media.set_predetermined_titles') }}
            </button>
          </div>
          <div class="checkbox checkbox-success m-t-20 m-b-20"
               v-if="showPageBreaksCheckbox && selectedMedia.length > 1"
          >
            <input
              type="checkbox"
              id="addPageBreaks"
              class="filled-in"
              v-model="pageBreaks"
            >
            <label class="custom-control custom-checkbox" for="addPageBreaks">
              <small>{{ $t('media.add_pagebreak_text') }}</small>
            </label>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Input from '../form/inputs/Input'
import Textarea from '../form/Textarea'
import Checkbox from '../form/Checkbox'
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'
import MediaMixin from '../mixins/Media'
import CropMixin from '../mixins/Crop'
import Modal from './Modal'
import Preloader from '../preloader/Preloader'
import VueCropper from 'vue-cropperjs'
import Tooltip from '../tooltip/Tooltip'
import Select from '../form/select/Select'
import MultiSelect from '../form/select/MultiSelect'
import CreateTagButton from '../article/ArticleCreateTagButton'
import Gallery from '../../model/GalleryModel'
import DamApi from '@/api/dam'

require('tracking')
require('tracking/build/data/face-min')

const MAX_FILE_SIZE = 103809024
const MIN_FILE_WIDTH = 600

export default {
  name: 'MediaEditModal',
  mixins: [MediaMixin, CropMixin],
  props: {
    selectedMedia: {
      type: Array
    },
    showPageBreaksCheckbox: {
      type: Boolean,
      default: false
    },
    isGallery: {
      type: Boolean,
      default: false
    },
    site: {
      type: [Number, Object],
      default: null
    },
    insertButtonLabel: {
      type: String,
      default: ''
    },
    /**
     * Set this to true if the media is created elsewhere to prevent double media creation.
     */
    skipCreatingMedia: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      croppedImageWidth: 200,
      croppedImageHeight: 100,
      cropperKey: 0,
      cropPosition: [],
      caption: [],
      createdDamMedia: [],
      pageBreaks: false,
      defaultImageCaption: '',
      defaultImageAttribution: '',
      maxFileSize: MAX_FILE_SIZE,
      minFileWidth: MIN_FILE_WIDTH,
      updateError: false,
      isLoading: false,
      showGallery: false,
      gallery: { ...Gallery, site: this.site },
      searchedTag: '',
      tags: []
    }
  },
  computed: {
    isValid () {
      if (this.defaultImageCaption !== '' && this.defaultImageAttribution !== '') {
        return true
      }
      let isValid = true
      for (let i = 0; i < this.selectedMedia.length; i++) {
        if (this.selectedMedia[i].imageCaption === '' && this.defaultImageCaption === '') {
          isValid = false
        }
        if (this.selectedMedia[i].imageAttribution === '' && this.defaultImageAttribution === '') {
          isValid = false
        }
      }
      return isValid
    },
    sites () {
      return this.$store.getters['site/enabledSites']()
    },
    insertLabel () {
      if (this.showGallery) {
        return this.$t('dam.create_gallery_insert_into_article')
      }
      return this.insertButtonLabel || this.$t('modal.insert')
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    appCheckbox: Checkbox,
    appPreloader: Preloader,
    appVueCropper: VueCropper,
    appTooltip: Tooltip,
    appSelect: Select,
    appMultiSelect: MultiSelect,
    appCreateTagButton: CreateTagButton
  },
  methods: {
    updateCropperDimensions (asset) {
      if (this.$refs.cropperContainer) {
        const containerWidth = this.$refs.cropperContainer[0].offsetWidth - 30
        this.croppedImageHeight = Math.min(asset.dimensions.height, 480)
        this.croppedImageWidth = Math.min(Math.max(asset.dimensions.width, containerWidth), containerWidth)
        this.cropperKey += 1
      }
    },
    removeSelectedMedia (index) {
      this.selectedMedia.splice(index, 1)
    },
    async saveGalleryMedia () {
      this.isLoading = true
      await Promise.all(this.selectedMedia.map(this.createMedia))
      if (this.updateError === false) {
        NotifyService.setSuccessMessage(this.$t('notify.media_metadata_was_updated'))
      }
      this.isLoading = false
      if (this.selectedMedia.length === 1) {
        const media = this.selectedMedia[0]
        await this.saveCrop(media.damMediaEmbed.damId, media.damMediaEmbed.filename)
          .then(() => {
            this.$emit('add-media', this.createdDamMedia)
            this.$emit('refresh-media', media.damMediaEmbed.filename)
            this.$emit('close')
          })
      } else {
        this.$emit('add-page-breaks', this.pageBreaks)
        this.$emit('add-media', this.createdDamMedia)
        if (this.showGallery) {
          try {
            this.gallery.expanded.medias = this.createdDamMedia
            const gallery = await this.$store.dispatch('gallery/create', this.gallery)
            this.$emit('set-gallery-group', gallery.id)
            NotifyService.setSuccessMessage(this.$t('gallery.notify.created'))
          } catch (err) {
            console.error(err)
            NotifyService.setErrorMessage(this.$t('gallery.notify.not_created'))
          }
        }
        this.$emit('close')
      }
    },
    async createMedia (media, index) {
      if (media.imageCaption === '') {
        media.imageCaption = this.defaultImageCaption
      }
      if (media.imageAttribution === '') {
        media.imageAttribution = this.defaultImageAttribution
      }
      // if this.showGallery is true, we still need to create a separate media for the gallery
      if (this.skipCreatingMedia && !this.showGallery) {
        this.createdDamMedia[index] = media
      } else {
        if (media.id) {
          await this.updateMedia(media, index)
        } else {
          await CoreApi().post('/media', JSON.stringify(media))
            .then(response => {
              this.createdDamMedia[index] = response.data
            })
            .catch(error => {
              console.error(error)
            })
        }
      }
    },
    async updateMedia (media, index) {
      await CoreApi().put('/media/' + media.id, JSON.stringify(media))
        .then((response) => {
          this.createdDamMedia[index] = response.data
        })
        .catch(error => {
          this.updateError = true
          console.log(error)
        })
    },
    close () {
      this.$emit('close')
    },
    removeTitles () {
      this.selectedMedia.forEach(media => {
        media.imageCaption = ''
      })
    },
    setPredeterminedTitles () {
      this.selectedMedia.forEach(media => {
        media.imageCaption = this.defaultImageCaption
        media.imageAttribution = this.defaultImageAttribution
      })
    },
    // CROP
    getDamCrop () {
      this.$store.dispatch('dam/getCrop', this.selectedMedia[0].damMediaEmbed.damId)
        .then(() => {
          this.dataLoaded = true
          this.cropPositionEmbed = this.$store.getters['dam/cropPositionEmbed']
          this.setCrop(this.cropPositionEmbed)
        })
    },
    setCrop (cropPositionEmbed) {
      const cropper = this.getCropperObject()
      if (cropper) {
        const imageData = cropper.getImageData()
        const x = imageData.naturalWidth * cropPositionEmbed.upperLeftCoords.xCoord
        const y = imageData.naturalHeight * cropPositionEmbed.upperLeftCoords.yCoord
        const width = (imageData.naturalWidth * cropPositionEmbed.lowerRightCoords.xCoord) - x
        const height = imageData.naturalWidth / (16 / 9)
        // let height = (imageData.naturalHeight * cropPositionEmbed.lowerRightCoords.yCoord) - y
        const dataProperties = {
          x: x,
          y: y,
          width: width,
          height: height
        }
        cropper.setData(dataProperties)
      }
    },
    getCropperObject () {
      if (this.$refs.cropper.length === 0) {
        return null
      }
      if (Array.isArray(this.$refs.cropper)) {
        return this.$refs.cropper[0].cropper
      } else {
        return this.$refs.cropper.cropper
      }
    },
    async saveCrop (assetId, assetBasename) {
      this.dataLoaded = true
      const cropper = this.getCropperObject()
      const cropData = cropper.getData()
      const imageData = cropper.getImageData()
      this.cropPositionEmbed = {
        upperLeftCoords: {
          xCoord: (cropData.x / imageData.naturalWidth),
          yCoord: (cropData.y / imageData.naturalHeight)
        },
        lowerRightCoords: {
          xCoord: ((cropData.x + cropData.width) / imageData.naturalWidth),
          yCoord: ((cropData.y + cropData.height) / imageData.naturalHeight)
        }
      }
      await this.$store.dispatch('dam/saveCrop', { assetId: assetId, cropPositionEmbed: this.cropPositionEmbed })
        .then(() => {
          this.dataLoaded = true
          this.setCrop(this.cropPositionEmbed)
          NotifyService.setSuccessMessage(this.$t('dam.crop_modal_was_set'))
        })
        .catch(() => {
          this.dataLoaded = true
        })
    },
    toggleGallery () {
      this.showGallery = !this.showGallery
    },
    addTag (tag) {
      this.gallery.expanded.tags.push(tag)
      this.searchedTag = ''
    },
    findTag (query) {
      this.$store.dispatch('tag/fetchByTitle', { query, view: 'gallery' })
        .then(() => {
          this.tags = this.$store.getters['tag/tagsWithGalleryCount']
          this.searchedTag = query
        })
    }
  },
  created () {
    this.setCropperWidth(800)
  },
  mounted () {
    DamApi().get('asset/' + this.selectedMedia[0].damMediaEmbed.damId)
      .then(response => {
        this.updateCropperDimensions(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>

<style scoped lang="scss">
  .media-edit-modal {
    &__remove-selected-media {
      position: absolute;
      top: 0;
      right: 0.9375rem;
      background: #fc4b6c;
      color: #fff;
      width: 2.1875rem;
      height: 2.1875rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 200ms;
      transform: scale(1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .check-image-warning .alert-warning {
    font-size: 12px;
  }
  .gallery {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
</style>
