<template>
  <div class="article-media">
    <div class="item">
      <a>
        <app-preloader v-if="croppedImageLoaded === false"></app-preloader>
        <img
          v-if="croppedImage !== ''"
          :src="croppedImage"
          :title="media.imageCaption"
          width="100%"
          height="100%"
        />
        <i
          v-if="!disabled"
          class="fas fa-edit media-edit"
          :title="$t('media.button_edit_metadata')"
          data-test="media_showMediaEditModal"
          @click="showMediaEditModal"
        >
        </i>
        <i
          v-if="!disabled"
          class="fas fa-crop media-crop"
          :title="$t('media.button_crop')"
          data-test="media_showMediaCropModal"
          @click="showMediaCropModal"
        >
        </i>
        <i
          v-if="!disabled"
          class="fas fa-download media-download"
          :title="$t('media.button_download')"
          @click="downloadMedia(media)"
        >
        </i>
        <i
          v-if="!disabled && showAddMediaToGallery"
          class="fas fa-images media-add-to-gallery"
          :title="$t('media.button_add_media_to_gallery')"
          data-test="media_addMediaToGallery"
          @click="addMediaToGallery"

        >
        </i>
        <i
          v-if="!disabled"
          class="fa fa-trash del media-delete"
          :title="$t('media.button_remove')"
          data-test="media_removeMedia"
          @click="removeMedia()"
        >
        </i>
      </a>
    </div>
    <div class="col-lg" v-if="showMediaInfo">
      <p>
        <strong>{{ $t('media.author') }}:</strong><br>
        {{ media.imageAttribution }}
      </p>
      <p>
        <strong>{{ $t('media.caption') }}:</strong><br>
        {{ media.imageCaption }}
      </p>
    </div>
    <app-media-edit-modal
      v-if="mediaEditModal"
      :selectedMedia="mediaToEdit"
      @add-media="addMedia"
      @close="closeMediaEditModal"
      @refresh-media="getCroppedImage($event, width, height)"
    >
    </app-media-edit-modal>
    <app-asset-crop-modal
      v-if="mediaCropModal"
      :asset="selectedAsset"
      @close="closeMediaCropModal"
      @post-update="getCroppedImage($event, width, height)"
    >
    </app-asset-crop-modal>
  </div>
</template>
<script>
import MediaMixin from '../mixins/Media'
import MediaEditModal from '../shared/MediaEditModal'
import AssetCropModal from '../dam/assetDetail/AssetCropModal'
import DamApi from '../../api/dam'
import Preloader from '../preloader/Preloader'

export default {
  name: 'ArticleGallery',
  mixins: [MediaMixin],
  props: {
    media: {
      type: Object
    },
    width: {
      type: Number,
      default: 360
    },
    height: {
      type: Number,
      default: 202
    },
    showMediaInfo: {
      type: Boolean,
      default: false
    },
    showAddMediaToGallery: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mediaEditModal: false,
      mediaCropModal: false,
      croppedImageLoaded: true,
      croppedImage: ''
    }
  },
  computed: {
    mediaToEdit () {
      return [
        this.media
      ]
    }
  },
  components: {
    appMediaEditModal: MediaEditModal,
    appAssetCropModal: AssetCropModal,
    appPreloader: Preloader
  },
  watch: {
    mediaToEdit () {
      if (this.media.damMediaEmbed) {
        this.croppedImageLoaded = false
        this.getCroppedImage(this.media.damMediaEmbed.filename, this.width, this.height)
      } else {
        this.croppedImage = null
      }
    }
  },
  methods: {
    removeMedia () {
      this.$emit('remove-media')
    },
    showMediaEditModal () {
      this.mediaEditModal = true
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    showMediaCropModal () {
      DamApi().get('asset/' + this.media.damMediaEmbed.damId)
        .then(response => {
          this.selectedAsset = response.data
          this.mediaCropModal = true
        })
        .catch(error => {
          console.log(error)
        })
    },
    closeMediaCropModal () {
      this.mediaCropModal = false
    },
    addMedia (media) {
      this.$emit('set-media', media)
    },
    addMediaToGallery () {
      this.$emit('add-media-to-gallery', [this.media])
    },
    getCroppedImage (basename, width = 800, height = 450) {
      DamApi().get('/image/' + basename + '/' + width + '/' + height)
        .then(response => {
          this.croppedImage = response.data.base64Image
          this.croppedImageLoaded = true
        })
        .catch(error => {
          console.log(error)
          this.croppedImageLoaded = true
        })
    }
  },
  created () {
    this.getCroppedImage(this.media.damMediaEmbed.filename, this.width, this.height)
  }
}
</script>

<style lang="scss">
.article-media {
  a {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
  }

  img {
    object-fit: cover;
  }

  .item {
    box-sizing: border-box;
    border-bottom: 3px solid #fff;
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .media-edit,
  .media-crop,
  .media-add-to-gallery,
  .media-delete,
  .media-download {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 10px;
    display: none;
    color: #fff;
  }

  .media-edit {
    left: 0;
    background: #2f3d4a;
  }

  .media-crop {
    left: 40px;
    background: #2f3d4a;
  }

  .media-download {
    left: 78px;
    background: #2f3d4a;
  }

  .media-add-to-gallery {
    background: #2f3d4a;
    right: 36px;
  }

  .media-delete {
    right: 0;
    background: #dc3545;
  }

  .item a:hover {
    .media-edit,
    .media-crop,
    .media-add-to-gallery,
    .media-delete,
    .media-download {
      display: block;
    }
  }
}
</style>
