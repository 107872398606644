<template>
    <span>
        <div
          v-if="showDropZone && !selectedDamMedia"
          class="image_dropzone text-center"
          @click="showDamSearchModal(null)"
          data-test="image_dropzone"
        >
            <span>{{ $t('media.select_image') }}</span>
        </div>
        <button
          class="btn btn-sm btn-info"
          :class="{ 'hide': hideButton }"
          ref="mediaSelectButton"
          @click="showDamSearchModal(null)"
        >
            <i class="fas fa-images"></i>
            {{ buttonText }}
        </button>
        <app-dam-search-modal
          v-if="damSearchModal"
          :multiple="multiple"
          :default-filter="damSearchDefaultFilter"
          @close="closeDamSearchModal"
          @show-media-edit-modal="showMediaEditModal"
        >
        </app-dam-search-modal>
        <app-media-edit-modal
          v-if="mediaEditModal"
          :selectedMedia="media"
          :showPageBreaksCheckbox="showPageBreaksCheckbox"
          :skip-creating-media="skipCreatingMedia"
          @add-media="addMedia"
          @close="closeMediaEditModal"
          @add-page-breaks="addPageBreaks"
          :isGallery="isGallery"
          :site="site"
          @set-gallery-group="setGalleryGroup"
          :insertButtonLabel="insertButtonLabel"
        >
        </app-media-edit-modal>
    </span>
</template>

<script>
import DamSearchModal from './DamSearchModal'
import MediaEditModal from './MediaEditModal'
import Gallery from '../../model/GalleryModel'
import MediaService from '@/services/media/MediaService'

export default {
  name: 'MediaSelectButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    showPageBreaksCheckbox: {
      type: Boolean,
      default: false
    },
    showDropZone: {
      type: Boolean,
      default: false
    },
    selectedDamMedia: {
      type: Object
    },
    isGallery: {
      type: Boolean,
      default: false
    },
    site: {
      type: [Number, Object],
      default: null
    },
    insertButtonLabel: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: function () {
        return this.$t('buttons.select_from_bank')
      }
    },
    skipCreatingMedia: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      damSearchModal: false,
      mediaEditModal: false,
      media: [],
      gallery: { ...Gallery, site: this.site },
      damSearchDefaultFilter: null
    }
  },
  components: {
    appMediaEditModal: MediaEditModal,
    appDamSearchModal: DamSearchModal
  },
  methods: {
    showDamSearchModal (damSearchDefaultFilter = null) {
      if (!this.hideButton) {
        this.damSearchDefaultFilter = damSearchDefaultFilter
        this.damSearchModal = true
        this.mediaEditModal = false
      }
    },
    closeDamSearchModal () {
      this.damSearchModal = false
    },
    showMediaEditModal (media) {
      this.media = []
      media.forEach(asset => {
        this.media.push(MediaService.mapDamAssetToCoreMedia(asset))
      })
      this.damSearchModal = false
      this.mediaEditModal = true
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    addMedia (media) {
      this.$emit('set-media', media)
    },
    addPageBreaks (pageBreaks) {
      this.$emit('add-page-breaks', pageBreaks)
    },
    setGalleryGroup (id) {
      this.$emit('set-gallery-group', id)
    }
  }
}
</script>

<style lang="scss">
.image_dropzone {
  width: 100%;
  height: 172px;
  margin-bottom: 5px;
  border: 1px dashed #d9d9d9;
  line-height: 160px;
  background: #ced4da;
  cursor: pointer;
}
</style>
